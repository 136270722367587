export default {
  address: '新北市五股區水碓二路、水碓七路口',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.886960330985!2d121.42774591500692!3d25.07182018395455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a63bb7e6ed95%3A0xdb1573ce2f206c43!2zMjQ45paw5YyX5biC5LqU6IKh5Y2A5rC056KT5LqU6LevODHomZ8!5e0!3m2!1szh-TW!2stw!4v1563020603251!5m2!1szh-TW!2stw',
  phone: '02-22918890',
  fbLink: 'https://m.facebook.com/心天畝-315443315712532/?ref=bookmarks',
  fbMessage: 'https://m.me/315443315712532',
  googleLink: 'https://goo.gl/maps/d4mpi3WPdJ2kLvsF7',
  caseName: '心天畝',

  houseInfos: [
    ['投資興建', '春木林建築團隊、泓利建設'],
    ['廣告銷售', '吉米開發'],
    ['建築設計', '廖錦盈建築師事務所'],
    ['工程營造', '春木林營造有限公司'],
    ['建照號碼', '106股建字第00205號'],
    ['基地地址', '新北市五股區水碓二路、水碓七路口'],
    ['接待中心', '新北市五股區水碓五路81號'],
    ['禮賓專線', '02-2291-8890']
  ],
  gtmCode: ['TFSP5SN'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
